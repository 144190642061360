enum Actions {
  FETCH_ALL_DRAW_ENTRIES = 'fetchAllDrawEntries',
  FETCH_DRAW_ENTRIES = 'fetchDrawEntries',
  FETCH_DRAW_ENTRY = 'fetchDrawEntry',
  UPDATE_DRAW_ENTRIES = 'updateDrawEntrie',
  STORE_DRAW_ENTRIES = 'createDrawEntrie',
  DELETE_DRAW_ENTRIES = 'deleteDrawEntrie',
  UPLOAD_DRAW_ENTRIES_PICTURE = 'uploadDrawEntriePicture',
}

enum Mutations {
  SET_ALL_DRAW_ENTRIES = 'setAllDrawEntries',
  SET_PAGINATION = 'setDrawEntriePagination',
  SET_DRAW_ENTRIES = 'setDrawEntries',
  SET_DRAW_ENTRY = 'setDrawEntry',
  SET_ERRORS = 'setDrawEntrieErrors',
  SET_ACTION_ERRORS = 'setDrawEntrieActionErrors',
}

enum Getters {
  GET_ALL_DRAW_ENTRIES = 'getAllDrawEntries',
  GET_PAGINATION = 'getDrawEntriePagination',
  GET_DRAW_ENTRIES = 'getDrawEntries',
  GET_DRAW_ENTRY = 'getDrawEntry',
  GET_ERRORS = 'getDrawEntrieErrors',
  GET_ACTION_ERRORS = 'getDrawEntrieActionErrors',
}

export { Actions, Mutations, Getters };
