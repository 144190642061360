enum Actions {
  INITIALIZE_MEMBER_LOTS = 'initializeMemberLots',
  FETCH_ALL_MEMBERS = 'fetchAllMembers',
  FETCH_MEMBERS = 'fetchMembers',
  FETCH_MEMBER = 'fetchMember',
  UPDATE_MEMBER = 'updateMember',
  CREATE_MEMBER = 'createMember',
  DELETE_MEMBER = 'deleteMember',
  CLEAR_MEMBER = 'clearMember',
}

enum Mutations {
  SET_ALL_MEMBERS = 'setAllMembers',
  SET_PAGINATION = 'setMemberPagination',
  SET_MEMBERS = 'setMembers',
  SET_MEMBER = 'setMember',
  SET_ERRORS = 'setMemberErrors',
  SET_ACTION_ERRORS = 'setMemberActionErrors',
}

enum Getters {
  GET_ALL_MEMBERS = 'getAllMembers',
  GET_PAGINATION = 'getMemberPagination',
  GET_MEMBERS = 'getMembers',
  GET_MEMBER = 'getMember',
  GET_ERRORS = 'getMemberErrors',
  GET_ACTION_ERRORS = 'getMemberActionErrors',
}

export { Actions, Mutations, Getters };
