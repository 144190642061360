import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/BundleEnums';
import { BundleModuleStore, StoreError } from '@/models/StoreModel';
import { Bundle } from '@/models/BundleModel';
import { ResourcePagination } from '@/models/CommonModel';

@Module
export default class BundleModule
  extends VuexModule
  implements BundleModuleStore
{
  actionError: StoreError | null = null;
  listError: StoreError | null = null;

  allBundles = [];
  bundles = [];
  bundle = null;

  listLoading = false;

  pagination = null as unknown as ResourcePagination;

  get [Getters.GET_ALL_BUNDLES](): Bundle[] {
    return this.allBundles as unknown as Array<Bundle>;
  }

  get [Getters.GET_BUNDLES](): Bundle[] {
    return this.bundles as unknown as Array<Bundle>;
  }

  get [Getters.GET_BUNDLE](): Bundle {
    return this.bundle as unknown as Bundle;
  }

  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  get [Getters.GET_PAGINATION](): ResourcePagination {
    return this.pagination;
  }

  @Mutation
  [Mutations.SET_ALL_BUNDLES](bundles) {
    this.allBundles = bundles;
  }

  @Mutation
  [Mutations.SET_BUNDLES](bundles) {
    this.bundles = bundles;
  }

  @Mutation
  [Mutations.SET_BUNDLE](bundle) {
    this.bundle = bundle;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Mutation
  [Mutations.SET_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Action
  [Actions.FETCH_ALL_BUNDLES](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.parameters(params);
      ApiService.get('admin/bundles?returnAll=true')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_BUNDLES, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_BUNDLES]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('admin/bundles')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BUNDLES, data.data.bundles);
          this.context.commit(Mutations.SET_PAGINATION, data.data.pagination);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_BUNDLE](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/bundles/${id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BUNDLE, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_BUNDLE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('admin/bundles', payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BUNDLES, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_BUNDLE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/bundles/${payload.bundleId}`, payload.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_BUNDLE](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/bundles/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }
}
