import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/DrawWinnerEnums';
import { DrawWinnerModuleStore, StoreError } from '@/models/StoreModel';
import { DrawWinner } from '@/models/DrawWinnerModel';
import { ResourcePagination } from '@/models/CommonModel';

@Module
export default class DrawWinnerModule
  extends VuexModule
  implements DrawWinnerModuleStore
{
  actionError: StoreError | null = null;
  listError: StoreError | null = null;

  allDrawWinners = [];
  drawWinner = null;
  drawWinners = [];

  listLoading = false;

  pagination = null as unknown as ResourcePagination;

  get [Getters.GET_PAGINATION](): ResourcePagination {
    return this.pagination;
  }

  get [Getters.GET_DRAW_WINNER](): DrawWinner {
    return this.drawWinner as unknown as DrawWinner;
  }

  get [Getters.GET_DRAW_WINNERS](): DrawWinner {
    return this.drawWinners as unknown as DrawWinner;
  }

  get [Getters.GET_ACTION_ERRORS](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  @Mutation
  [Mutations.SET_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_DRAW_WINNERS](bundles) {
    this.drawWinners = bundles;
  }

  @Mutation
  [Mutations.SET_DRAW_WINNER](bundle) {
    this.drawWinner = bundle;
  }

  @Mutation
  [Mutations.SET_ACTION_ERRORS](error) {
    this.actionError = error;
  }

  @Action
  [Actions.FETCH_DRAW_WINNERS](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/draws/${params.drawId}/winners`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DRAW_WINNERS, data.data);
          this.context.commit(Mutations.SET_PAGINATION, data.data.pagination);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_DRAW_WINNER](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/draws/${params.drawId}/winners/${params.winnerId}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DRAW_WINNERS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_DRAW_WINNER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/draws/${payload.drawId}/winners`, payload.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_DRAW_WINNER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(
        `admin/draws/${payload.drawId}/winners/${payload.bundleId}`,
        payload.data
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_DRAW_WINNER](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/draws/${params.drawId}/winners/${params.id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_DRAW_WINNER_PICTURE](payload) {
    return new Promise<void>((resolve, reject) => {
      const form = new FormData();
      form.append('image', payload.image);

      ApiService.post(
        `admin/draws/${payload.drawId}/winners/${payload.id}/image`,
        form
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }
}
