enum Actions {
  CREATE_AGENCY = 'createAgency',
  UPDATE_AGENCY = 'updateAgency',
  DELETE_AGENCY = 'deleteAgency',
  FETCH_AGENCIES = 'fetchAgencies',
  FETCH_AGENCY = 'fetchSingleAgency',
  FETCH_AGENCY_USERS = 'fetchUsers',
  FETCH_REFERENCES = 'fetchReferences',
  UPLOAD_LOGO = 'uploadLogo',
}

enum Mutations {
  SET_AGENCIES = 'setAgencies',
  SET_AGENCY = 'setSingleAgency',
  SET_AGENCY_USERS = 'setAgencyUsers',
  SET_ACTION_ERRORS = 'setAgencyActionErrors',
  SET_PAGINATION = 'setAgencyPagination',
}

enum Getters {
  GET_AGENCIES = 'getAgencies',
  GET_AGENCY = 'getSingleAgency',
  GET_AGENCY_USERS = 'getAgencyUsers',
  GET_ACTION_ERRORS = 'getAgencyActionErrors',
  GET_PAGINATION = 'getAgencyPagination',
}

export { Actions, Mutations, Getters };
