import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/MemberEnums';
import { MemberModuleStore, StoreError } from '@/models/StoreModel';
import { User } from '@/models/UserModel';
import { ResourcePagination } from '@/models/CommonModel';

@Module
export default class MemberModule
  extends VuexModule
  implements MemberModuleStore
{
  actionError: StoreError | null = null;
  dataError: StoreError | null = null;

  allMembers = [] as unknown as User[];
  members = {} as unknown as User[];
  member = null as unknown as User;
  pagination = null as unknown as ResourcePagination;

  listError: StoreError | null = null;
  listLoading = false;

  get [Getters.GET_ALL_MEMBERS](): User[] {
    return this.allMembers;
  }

  get [Getters.GET_PAGINATION](): ResourcePagination {
    return this.pagination;
  }

  get [Getters.GET_MEMBERS](): User[] {
    return this.members;
  }

  get [Getters.GET_MEMBER](): User {
    return this.member;
  }

  get [Getters.GET_ERRORS](): StoreError {
    return this.dataError as StoreError;
  }

  get [Getters.GET_ACTION_ERRORS](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_ALL_MEMBERS](members) {
    this.allMembers = members;
  }

  @Mutation
  [Mutations.SET_MEMBERS](members) {
    this.members = members;
  }

  @Mutation
  [Mutations.SET_MEMBER](member) {
    this.member = member;
  }

  @Mutation
  [Mutations.SET_ERRORS](error) {
    this.dataError = error;
  }

  @Mutation
  [Mutations.SET_ACTION_ERRORS](error) {
    this.actionError = error;
  }

  @Action
  [Actions.CLEAR_MEMBER]() {
    this.context.commit(Mutations.SET_MEMBER, '');
  }

  @Action
  [Actions.FETCH_ALL_MEMBERS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/members?returnAll=true`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_MEMBERS, data.data);
          resolve();
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_MEMBERS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/members`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_MEMBERS, data.data.members);
          this.context.commit(Mutations.SET_PAGINATION, data.data.pagination);
          resolve();
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_MEMBER](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/members/${params.id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_MEMBER, data.data);
          resolve();
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_MEMBER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/members`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_MEMBER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/members/${payload.id}`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_MEMBER](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/members/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((response) => {
          console.log('DELETE_MEMBER', response);
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.INITIALIZE_MEMBER_LOTS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/members/initialize`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }
}
