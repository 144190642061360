import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/DrawEnums';
import { DrawModuleStore, StoreError } from '@/models/StoreModel';
import { Draw } from '@/models/DrawModel';
import { ResourcePagination } from '@/models/CommonModel';

@Module
export default class DrawModule extends VuexModule implements DrawModuleStore {
  actionError: StoreError | null = null;
  dataError: StoreError | null = null;

  allDraws = [] as unknown as Draw[];
  draws = {} as unknown as Draw[];
  draw = null as unknown as Draw;
  pagination = null as unknown as ResourcePagination;

  get [Getters.GET_ALL_DRAWS](): Draw[] {
    return this.allDraws;
  }

  get [Getters.GET_PAGINATION](): ResourcePagination {
    return this.pagination;
  }

  get [Getters.GET_DRAWS](): Draw[] {
    return this.draws;
  }

  get [Getters.GET_DRAW](): Draw {
    return this.draw;
  }

  get [Getters.GET_ERRORS](): StoreError {
    return this.dataError as StoreError;
  }

  get [Getters.GET_ACTION_ERRORS](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_ALL_DRAWS](draws) {
    this.allDraws = draws;
  }

  @Mutation
  [Mutations.SET_DRAWS](draws) {
    this.draws = draws;
  }

  @Mutation
  [Mutations.SET_DRAW](draw) {
    this.draw = draw;
  }

  @Mutation
  [Mutations.SET_ERRORS](error) {
    this.dataError = error;
  }

  @Mutation
  [Mutations.SET_ACTION_ERRORS](error) {
    this.actionError = error;
  }

  @Action
  [Actions.CLEAR_DRAW]() {
    this.context.commit(Mutations.SET_DRAW, '');
  }

  @Action
  [Actions.FETCH_ALL_DRAWS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/draws`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_DRAWS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_DRAWS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/draws`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DRAWS, data.data.draws);
          this.context.commit(Mutations.SET_PAGINATION, data.data.pagination);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_DRAW](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/draws/${params.id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DRAW, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_DRAW](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/draws`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_DRAW](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/draws/${payload.id}`, payload.data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DRAW, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_DRAW](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/draws/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          console.log('response', response);
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.INITIALIZE_DRAW_LOTS](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/draws/${params.drawId}/initialize`, params.data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DRAW, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.PICK_DRAW_WINNER](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/draws/${params.drawId}/pick`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          console.log('response', response.data);
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_DRAW_PICTURE](payload) {
    return new Promise<void>((resolve, reject) => {
      const form = new FormData();
      form.append('image', payload.image);

      ApiService.post(`admin/draws/${payload.id}/image`, form, {})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DRAW, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.SEND_DRAW_NOTIFICATIONS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/draws/notifications`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }
}
