enum Actions {
  INITIALIZE_DRAW_LOTS = 'initializeDrawLots',
  PICK_DRAW_WINNER = 'pickDrawWinner',
  FETCH_ALL_DRAWS = 'fetchAllDraws',
  FETCH_DRAWS = 'fetchDraws',
  FETCH_DRAW = 'fetchDraw',
  UPDATE_DRAW = 'updateDraw',
  CREATE_DRAW = 'createDraw',
  DELETE_DRAW = 'deleteDraw',
  CLEAR_DRAW = 'clearDraw',
  UPLOAD_DRAW_PICTURE = 'uploadDrawPicture',
  SEND_DRAW_NOTIFICATIONS = 'sendDrawNotifications',
}

enum Mutations {
  SET_ALL_DRAWS = 'setAllDraws',
  SET_PAGINATION = 'setDrawPagination',
  SET_DRAWS = 'setDraws',
  SET_DRAW = 'setDraw',
  SET_ERRORS = 'setDrawErrors',
  SET_ACTION_ERRORS = 'setDrawActionErrors',
}

enum Getters {
  GET_ALL_DRAWS = 'getAllDraws',
  GET_PAGINATION = 'getDrawPagination',
  GET_DRAWS = 'getDraws',
  GET_DRAW = 'getDraw',
  GET_ERRORS = 'getDrawErrors',
  GET_ACTION_ERRORS = 'getDrawActionErrors',
}

export { Actions, Mutations, Getters };
