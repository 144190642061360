import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/AgencyEnums';
import { AgencyModuleStore, StoreError } from '@/models/StoreModel';
import { TenantReview } from '@/models/TenantReviewModel';
import { ResourcePagination } from '@/models/CommonModel';
import { Business } from '@/models/BusinessModel';
import { User } from '@/models/UserModel';

@Module
export default class AgencyModule
  extends VuexModule
  implements AgencyModuleStore
{
  actionError: StoreError | null = null;
  agencies = [] as unknown as Business[];
  agency = {} as unknown as Business;
  users = [] as unknown as User[];
  references = [] as unknown as TenantReview[];

  pagination = null as unknown as ResourcePagination;

  get [Getters.GET_AGENCIES](): Business[] {
    return this.agencies;
  }

  get [Getters.GET_AGENCY](): Business {
    return this.agency;
  }

  get [Getters.GET_AGENCY_USERS](): User[] {
    return this.users;
  }

  get [Getters.GET_ACTION_ERRORS](): StoreError {
    return this.actionError as StoreError;
  }

  get [Getters.GET_PAGINATION](): ResourcePagination {
    return this.pagination as ResourcePagination;
  }

  @Mutation
  [Mutations.SET_AGENCIES](agencies) {
    this.agencies = agencies;
  }

  @Mutation
  [Mutations.SET_AGENCY](agency) {
    this.agency = agency;
  }

  @Mutation
  [Mutations.SET_AGENCY_USERS](users) {
    this.users = users;
  }

  @Mutation
  [Mutations.SET_ACTION_ERRORS](errors) {
    this.actionError = errors;
  }

  @Mutation
  [Mutations.SET_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Action
  [Actions.FETCH_AGENCIES]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/agencies`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AGENCIES, data.data.agencies);
          this.context.commit(Mutations.SET_PAGINATION, data.data.pagination);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_AGENCY](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/agencies/${params.id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AGENCY, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_AGENCY](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/agencies`, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AGENCY, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_AGENCY](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/agencies/${payload.id}`, payload.data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AGENCY, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_AGENCY](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/agencies/${id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AGENCY, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_LOGO](payload) {
    return new Promise<void>((resolve, reject) => {
      const form = new FormData();
      form.append('image', payload.image);

      ApiService.post(`admin/agencies/${payload.id}/image`, form, {})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AGENCY, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }
}
