import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/DrawBundleEnums';
import { DrawBundleModuleStore, StoreError } from '@/models/StoreModel';
import { DrawBundle } from '@/models/DrawBundleModel';
import { ResourcePagination } from '@/models/CommonModel';

@Module
export default class DrawBundleModule
  extends VuexModule
  implements DrawBundleModuleStore
{
  actionError: StoreError | null = null;
  listError: StoreError | null = null;

  allDrawBundles = [];
  drawBundle = null;
  drawBundles = [];

  listLoading = false;

  pagination = null as unknown as ResourcePagination;

  get [Getters.GET_PAGINATION](): ResourcePagination {
    return this.pagination;
  }

  get [Getters.GET_DRAW_BUNDLE](): DrawBundle {
    return this.drawBundle as unknown as DrawBundle;
  }

  get [Getters.GET_DRAW_BUNDLES](): DrawBundle {
    return this.drawBundles as unknown as DrawBundle;
  }

  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  @Mutation
  [Mutations.SET_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_DRAW_BUNDLES](bundles) {
    this.drawBundles = bundles;
  }

  @Mutation
  [Mutations.SET_DRAW_BUNDLE](bundle) {
    this.drawBundle = bundle;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Action
  [Actions.FETCH_DRAW_BUNLDES](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/draws/${params.drawId}/bundles`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DRAW_BUNDLES, data.data.bundles);
          this.context.commit(Mutations.SET_PAGINATION, data.data.pagination);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_DRAW_BUNLDE](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/draws/${params.drawId}/bundles/${params.bundleId}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DRAW_BUNDLES, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_DRAW_BUNLDES](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/draws/${payload.drawId}/bundles`, payload.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_DRAW_BUNLDES](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(
        `admin/draws/${payload.drawId}/bundles/${payload.bundleId}`,
        payload.data
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_DRAW_BUNLDES](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/draws/${params.drawId}/bundles/${params.id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }
}
