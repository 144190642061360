enum Actions {
  FETCH_ALL_WINNERS = 'fetchAllDrawWinners',
  FETCH_DRAW_WINNERS = 'fetchDrawWinners',
  FETCH_DRAW_WINNER = 'fetchDrawWinner',
  UPDATE_DRAW_WINNER = 'updateDrawWinner',
  STORE_DRAW_WINNER = 'createDrawWinner',
  DELETE_DRAW_WINNER = 'deleteDrawWinner',
  UPLOAD_DRAW_WINNER_PICTURE = 'uploadDrawWinnerPicture',
}

enum Mutations {
  SET_ALL_DRAW_WINNERS = 'setAllDrawWinners',
  SET_PAGINATION = 'setDrawWinnerPagination',
  SET_DRAW_WINNERS = 'setDrawWinners',
  SET_DRAW_WINNER = 'setDrawWinner',
  SET_ERRORS = 'setDrawWinnerErrors',
  SET_ACTION_ERRORS = 'setDrawWinnerActionErrors',
}

enum Getters {
  GET_ALL_DRAW_WINNERS = 'getAllDrawWinners',
  GET_PAGINATION = 'getDrawWinnerPagination',
  GET_DRAW_WINNERS = 'getDrawWinners',
  GET_DRAW_WINNER = 'getDrawWinner',
  GET_ERRORS = 'getDrawWinnerErrors',
  GET_ACTION_ERRORS = 'getDrawWinnerActionErrors',
}

export { Actions, Mutations, Getters };
