enum Actions {
  FETCH_PLAN_BUNLDES = 'fetchPlanBundles',
  FETCH_PLAN_BUNLDE = 'fetchPlanBundle',
  STORE_PLAN_BUNLDES = 'storePlanBundles',
  UPDATE_PLAN_BUNLDES = 'updatePlanBundles',
  DELETE_PLAN_BUNLDES = 'deletePlanBundle',
}

enum Mutations {
  SET_PLAN_BUNDLE = 'setPlanBundle',
  SET_PLAN_BUNDLES = 'setPlanBundles',
  SET_PAGINATION = 'setPlanBundlePagination',
  SET_ACTION_ERROR = 'setPlanBundleActionError',
}

enum Getters {
  GET_PLAN_BUNDLE = 'getPlanBundle',
  GET_PLAN_BUNDLES = 'getPlanBundles',
  GET_PAGINATION = 'getPlanBundlePagination',
  GET_ACTION_ERROR = 'getPlanBundleActionError',
}

export { Actions, Mutations, Getters };
