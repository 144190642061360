enum Actions {
  FETCH_BUNDLES = 'fetchBundles',
  FETCH_ALL_BUNDLES = 'fetchAllBundles',
  STORE_BUNDLE = 'storeBundle',
  FETCH_BUNDLE = 'fetchBundle',
  UPDATE_BUNDLE = 'updateBundle',
  DELETE_BUNDLE = 'deleteBundle',
}

enum Mutations {
  SET_BUNDLE = 'setBundle',
  SET_BUNDLES = 'setBundles',
  SET_ALL_BUNDLES = 'setAllBundles',
  SET_PAGINATION = 'setBundlePagination',
  SET_ACTION_ERROR = 'setBundleActionError',
}

enum Getters {
  GET_BUNDLE = 'getBundle',
  GET_BUNDLES = 'getBundles',
  GET_ALL_BUNDLES = 'getAllBundles',
  GET_PAGINATION = 'getBundlePagination',
  GET_ACTION_ERROR = 'getBundleActionError',
}

export { Actions, Mutations, Getters };
