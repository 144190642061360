enum Actions {
  FETCH_ALL_PROMOS = 'fetchPromos',
  CREATE_PROMO = 'createPromo',
  FETCH_PROMO = 'fetchPromo',
  UPDATE_PROMO = 'updatePromo',
  DELETE_PROMO = 'deletePromo',
  CLEAR_PROMO = 'clearPromo',
  UPLOAD_LOGO = 'uploadPromoImage',
}

enum Mutations {
  SET_ALL_PROMOS = 'setAllPromo',
  SET_PROMO = 'setPromo',
  SET_PROMOS = 'setPromos',
  SET_PAGINATION = 'setPromoPagination',
  SET_DATA_ERROR = 'setPromoDataError',
  SET_ACTION_ERROR = 'setPromoActionError',
}

enum Getters {
  GET_ALL_PROMOS = 'getAllPromos',
  GET_PROMO = 'getPromo',
  GET_PROMOS = 'getPromos',
  GET_PAGINATION = 'getPromoPagination',
  GET_DATA_ERROR = 'getPromoDataError',
  GET_ACTION_ERRORS = 'getDataActionError',
}

export { Actions, Mutations, Getters };
