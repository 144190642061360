import { Action, Mutation, Module, VuexModule } from 'vuex-module-decorators';
import {
  Actions,
  Mutations as TenantUserMutations,
  Getters,
} from '../enums/ManageUserEnums';
import { Mutations } from '../enums/StoreEnums';
import ApiService from '@/core/services/ApiService';
import { filter, get } from 'lodash';
import { IStep1, IStep2 } from '@/models/submit-report/types';
import { ResourcePagination } from '@/models/CommonModel';

interface TenantUser {
  first_name: string;
  last_name: string;
  date_of_birth?: string;
}

export interface TenantRecord extends TenantUser {
  id?: string;
  mobile_number: string;
  email: string;
}

@Module
export default class ManageUserModule extends VuexModule {
  tenantUsers = {} as TenantRecord;
  pagination = {} as ResourcePagination;
  user = {};
  error = null as any;

  get [Getters.GET_USER_LIST](): any {
    return this.tenantUsers;
  }

  get userListPagination(): ResourcePagination {
    return this.pagination;
  }

  get [Getters.GET_USER_ADDED](): any {
    return this.user;
  }

  get [Getters.GET_ERROR](): any {
    return this.error;
  }

  @Mutation
  [TenantUserMutations.SET_ERROR](payload) {
    this.error = payload;
  }

  @Mutation
  [TenantUserMutations.SET_USER_LIST](payload) {
    this.tenantUsers = payload;
  }

  @Mutation
  [TenantUserMutations.SET_USER_PAGINATION](payload) {
    this.pagination = payload;
  }

  @Mutation
  [TenantUserMutations.SET_USER_ADDED](payload) {
    this.user = payload;
  }

  @Action
  [Actions.FETCH_USER_LIST](page_number = 1 as number, search = '' as string) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/users?page=${page_number}&search=${search}`)
        .then((response) => {
          const user_list = get(response, 'data.data.users') as Array<{}>;
          const pagination = get(response, 'data.data.pagination') as Array<{}>;

          this.context.commit(TenantUserMutations.SET_USER_LIST, user_list);
          this.context.commit(
            TenantUserMutations.SET_USER_PAGINATION,
            pagination
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_USER_LIST](id: string | number) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/users/${id}`)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(TenantUserMutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.EDIT_USER_FROM_LIST](payload) {
    const id = get(payload, 'id');
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/users/${id}`, payload)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(TenantUserMutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.ADD_USER_LIST](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('admin/users', payload)
        .then((response) => {
          resolve(response);
          // this.context.commit(TenantUserMutations.SET_USER_ADDED, response.data.data)
        })
        .catch(({ response }) => {
          this.context.commit(TenantUserMutations.SET_ERROR, response.data);
          reject(response);
        });
    });
  }
}
