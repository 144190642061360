import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/UserEntryEnums';
import { UserEntryModuleStore, StoreError } from '@/models/StoreModel';
import { UserEntry } from '@/models/UserEntryModel';
import { ResourcePagination } from '@/models/CommonModel';

@Module
export default class UserEntryModule
  extends VuexModule
  implements UserEntryModuleStore
{
  actionError: StoreError | null = null;
  listError: StoreError | null = null;

  allUserEntries = [];
  userEntry = null;
  userEntries = [];

  listLoading = false;

  pagination = null as unknown as ResourcePagination;

  get [Getters.GET_PAGINATION](): ResourcePagination {
    return this.pagination;
  }

  get [Getters.GET_USER_ENTRY](): UserEntry {
    return this.userEntry as unknown as UserEntry;
  }

  get [Getters.GET_USER_ENTRIES](): UserEntry {
    return this.userEntries as unknown as UserEntry;
  }

  get [Getters.GET_ACTION_ERRORS](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  @Mutation
  [Mutations.SET_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_USER_ENTRIES](entries) {
    this.userEntries = entries;
  }

  @Mutation
  [Mutations.SET_USER_ENTRY](entry) {
    this.userEntry = entry;
  }

  @Mutation
  [Mutations.SET_ACTION_ERRORS](error) {
    this.actionError = error;
  }

  @Action
  [Actions.FETCH_USER_ENTRIES](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/entries/${params.drawId}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_ENTRIES, data.data.entries);
          this.context.commit(Mutations.SET_PAGINATION, data.data.pagination);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_USER_ENTRY](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/entries/${params.code}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_ENTRY, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_USER_ENTRIES](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/entries`, payload.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_USER_ENTRIES](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/entries/${payload.entryId}`, payload.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_USER_ENTRIES](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/entries/${params.id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }
}
