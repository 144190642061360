enum Actions {
  FETCH_ALL_ENTRIES = 'fetchAllUserEntries',
  FETCH_USER_ENTRIES = 'fetchUserEntries',
  FETCH_USER_ENTRY = 'fetchUserEntry',
  UPDATE_USER_ENTRIES = 'updateUserEntries',
  STORE_USER_ENTRIES = 'createUserEntries',
  DELETE_USER_ENTRIES = 'deleteUserEntries',
}

enum Mutations {
  SET_ALL_USER_ENTRIES = 'setAllUserEntries',
  SET_PAGINATION = 'setUserEntriePagination',
  SET_USER_ENTRIES = 'setUserEntries',
  SET_USER_ENTRY = 'setUserEntry',
  SET_ERRORS = 'setUserEntrieErrors',
  SET_ACTION_ERRORS = 'setUserEntrieActionErrors',
}

enum Getters {
  GET_ALL_USER_ENTRIES = 'getAllUserEntries',
  GET_PAGINATION = 'getUserEntriePagination',
  GET_USER_ENTRIES = 'getUserEntries',
  GET_USER_ENTRY = 'getUserEntry',
  GET_ERRORS = 'getUserEntrieErrors',
  GET_ACTION_ERRORS = 'getUserEntrieActionErrors',
}

export { Actions, Mutations, Getters };
