import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/PromoEnums';
import { PromoModuleStore, StoreError } from '@/models/StoreModel';
import { Promo } from '@/models/PromoModel';
import { ResourcePagination } from '@/models/CommonModel';

@Module
export default class PromoModule
  extends VuexModule
  implements PromoModuleStore
{
  actionError: StoreError | null = null;
  dataError: StoreError | null = null;

  allPromos = [] as unknown as Promo[];
  promos = {} as unknown as Promo[];
  promo = null as unknown as Promo;
  pagination = null as unknown as ResourcePagination;

  get [Getters.GET_ALL_PROMOS](): Promo[] {
    return this.allPromos;
  }

  get [Getters.GET_PAGINATION](): ResourcePagination {
    return this.pagination;
  }

  get [Getters.GET_PROMOS](): Promo[] {
    return this.promos;
  }

  get [Getters.GET_PROMO](): Promo {
    return this.promo;
  }

  get [Getters.GET_DATA_ERROR](): StoreError {
    return this.dataError as StoreError;
  }

  get [Getters.GET_ACTION_ERRORS](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_ALL_PROMOS](promos) {
    this.allPromos = promos;
  }

  @Mutation
  [Mutations.SET_PROMOS](promos) {
    this.promos = promos;
  }

  @Mutation
  [Mutations.SET_PROMO](promo) {
    this.promo = promo;
  }

  @Mutation
  [Mutations.SET_DATA_ERROR](error) {
    this.dataError = error;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Action
  [Actions.CLEAR_PROMO]() {
    this.context.commit(Mutations.SET_PROMO, '');
  }

  @Action
  [Actions.FETCH_ALL_PROMOS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/promos`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_PROMOS, data.data.promos);
          this.context.commit(Mutations.SET_PAGINATION, data.data.pagination);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_PROMO](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/promos/${params.id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PROMO, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_PROMO](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/promos`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_PROMO](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/promos/${payload.id}`, payload.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_PROMO](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/promos/${id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_LOGO](payload) {
    return new Promise<void>((resolve, reject) => {
      const form = new FormData();
      form.append('image', payload.image);

      ApiService.post(`admin/promos/${payload.id}/image`, form)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PROMO, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }
}
