enum Actions {
  FETCH_DRAW_BUNLDES = 'fetchDrawBundles',
  FETCH_DRAW_BUNLDE = 'fetchDrawBundle',
  STORE_DRAW_BUNLDES = 'storeDrawBundles',
  UPDATE_DRAW_BUNLDES = 'updateDrawBundles',
  DELETE_DRAW_BUNLDES = 'deleteDrawBundle',
}

enum Mutations {
  SET_DRAW_BUNDLE = 'setDrawBundle',
  SET_DRAW_BUNDLES = 'setDrawBundles',
  SET_PAGINATION = 'setDrawBundlePagination',
  SET_ACTION_ERROR = 'setDrawBundleActionError',
}

enum Getters {
  GET_DRAW_BUNDLE = 'getDrawBundle',
  GET_DRAW_BUNDLES = 'getDrawBundles',
  GET_PAGINATION = 'getDrawBundlePagination',
  GET_ACTION_ERROR = 'getDrawBundleActionError',
}

export { Actions, Mutations, Getters };
