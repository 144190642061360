import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/DrawEntryEnums';
import { DrawEntryModuleStore, StoreError } from '@/models/StoreModel';
import { DrawEntry } from '@/models/DrawEntryModel';
import { ResourcePagination } from '@/models/CommonModel';

@Module
export default class DrawEntryModule
  extends VuexModule
  implements DrawEntryModuleStore
{
  actionError: StoreError | null = null;
  listError: StoreError | null = null;

  allDrawEntries = [];
  drawEntry = null;
  drawEntries = [];

  listLoading = false;

  pagination = null as unknown as ResourcePagination;

  get [Getters.GET_PAGINATION](): ResourcePagination {
    return this.pagination;
  }

  get [Getters.GET_DRAW_ENTRY](): DrawEntry {
    return this.drawEntry as unknown as DrawEntry;
  }

  get [Getters.GET_DRAW_ENTRIES](): DrawEntry {
    return this.drawEntries as unknown as DrawEntry;
  }

  get [Getters.GET_ACTION_ERRORS](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  @Mutation
  [Mutations.SET_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_DRAW_ENTRIES](entries) {
    this.drawEntries = entries;
  }

  @Mutation
  [Mutations.SET_DRAW_ENTRY](entry) {
    this.drawEntry = entry;
  }

  @Mutation
  [Mutations.SET_ACTION_ERRORS](error) {
    this.actionError = error;
  }

  @Action
  [Actions.FETCH_ALL_DRAW_ENTRIES](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/draws/${params.drawId}/entries?returnAll=true`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_DRAW_ENTRIES, data.data);
          this.context.commit(Mutations.SET_PAGINATION, data.data.pagination);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_DRAW_ENTRIES](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/draws/${params.drawId}/entries`)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_DRAW_ENTRIES,
            data.data.draw_entries
          );
          this.context.commit(Mutations.SET_PAGINATION, data.data.pagination);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_DRAW_ENTRY](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/draws/${params.drawId}/entries/${params.winnerId}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DRAW_ENTRIES, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_DRAW_ENTRIES](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/draws/${payload.drawId}/entries`, payload.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_DRAW_ENTRIES](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(
        `admin/draws/${payload.drawId}/entries/${payload.entryId}`,
        payload.data
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_DRAW_ENTRIES](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/draws/${params.drawId}/entries/${params.id}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERRORS, response);
          reject();
        });
    });
  }
}
